import React, { useState, useEffect } from "react";
import SignUpForm from "./SignUp/SignUpForm";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  linkExpiredValidationService,
  saveandResumeTokenValidation,
} from "../../services/auth.service";
import Loader from "../../components/Loader";
import { languagequeryParam, setReferingBroker, setUserEntity } from "../../reducers/signUpSlice";
import { updateUserInfo } from "../../reducers/userInfoSlice";
import { getPaymentStatusByFxowaccount } from "../../utils/getPaymentStatusByFxowaccount";
import { configurationServices } from "../../constants/configurationServices";
import { storelanguageList } from "../../reducers/commonSlice";
import { userInfoData } from "../../services/userInfo.service";
import { getAPIPath } from "../../utils/endPointMapper";
import { getHeaders } from "../../utils/getUsersDetails";
import { customAxios } from "../../services/serviceInterceptor";
import { thresholdDataSave } from "../../services/threshold.service";
import { notifyApiFail } from "../../reducers/errorNotifySlice";
import Cookies from 'js-cookie';

const OnboardingPage = () => {
  const BASE_URL = getAPIPath().REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const {
    expiredLinkValidationRes,
    autoLoginResponse,
    loginRes,
    userEntity,
    authToken,
  } = useSelector((state) => state.signUp);
  const queryParameters = new URLSearchParams(window.location.search);
  const resetPasswordParam = window.location
    .toString()
    .includes("validatepasswordtoken");
  const saveAndResumeParam = window.location.toString().includes("autoLogin");
  const [isLinkValid, setIsLinkValid] = useState(true);
  const { ip } = useSelector((state) => state.ipAddress);
  const referingBroker = queryParameters.get("rb");
/* istanbul ignore next */
  useEffect(() => {//NOSONAR
    if (!isLinkValid && resetPasswordParam && Object.keys(expiredLinkValidationRes).length != 0) {
      setIsLinkValid(true);
      if (expiredLinkValidationRes?.data?.emailAddress) {
        localStorage.setItem(
          "usermail",
          expiredLinkValidationRes.data.emailAddress
        );
      }
      if (expiredLinkValidationRes?.success === "false" ||
        expiredLinkValidationRes?.success === false ||
        expiredLinkValidationRes?.success === null ||
        expiredLinkValidationRes?.httpStatus === "SERVICE_UNAVAILABLE") {
        navigate("/link-error");
      } else if (expiredLinkValidationRes?.data?.linkValid) {
        if (expiredLinkValidationRes?.data?.token) {
          localStorage.setItem("uPassToken", expiredLinkValidationRes?.data?.token)
          localStorage.setItem("userLocale", expiredLinkValidationRes?.data?.userLocale)
          navigate("/resetPassword");
        } else
          navigate("/invalid-token");
      } else if (!expiredLinkValidationRes?.data?.linkValid) {
        if (expiredLinkValidationRes?.data?.emailAddress)
          navigate("/expiredLink");
        else {
          navigate("/link-error");
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiredLinkValidationRes, isLinkValid, resetPasswordParam]);

/* istanbul ignore next */
  useEffect(() => {//NOSONAR
    if (!isLinkValid && saveAndResumeParam) {
      setIsLinkValid(true);
      if (autoLoginResponse?.success) {
        let autoLoginMessage = autoLoginResponse?.data["login-response"]?.message;
        if (autoLoginMessage === "TOKEN_EXPIRED") {
          localStorage.setItem("autoLoginMail", autoLoginResponse?.data["login-response"]?.email);
          navigate("/expiredLink");
        } else if (autoLoginMessage === "DEFAULT_LOGIN") {
          window.location.replace("/login");
        } else if (autoLoginMessage === "Login Success") {
          Cookies.set("token", loginRes.data["login-response"].token);
          const currentStep =
            loginRes?.data["user-details"]?.userForms?.currentStep
              ? loginRes?.data["user-details"]?.userForms?.currentStep
              : "";
          let accRejectionStatus = "SUCCESS";
          if (loginRes.data["user-details"]?.userForms?.currentStep === "ONBOARDING_COMPLETE") {
            if (loginRes.data["user-details"]?.userForms?.accountRejectionReason)
              accRejectionStatus = loginRes.data["user-details"]?.userForms?.accountRejectionReason
          }
          let usersinfoData = {
            countryOfResidence: loginRes.data["user-details"].countryOfResidence,
            email: loginRes.data["user-details"].email,
            userName: loginRes.data["user-details"].username,
            authToken: loginRes.data["login-response"].token,
            locale: loginRes?.data["user-details"]?.userLocale,
            fundingStatus: loginRes?.data["user-details"]?.userForms?.fundingStatus,
            preCddEligible: loginRes?.data["user-details"]?.preCddEligible,
            applicationStatus: loginRes?.data["user-details"]?.userForms?.applicationStatus,
            crsStatus: loginRes?.data["user-details"]?.userForms?.crsStatus,
            fxowsAccountNumber: loginRes?.data["user-details"]?.fxowsAccountNumber,
            currentStep: loginRes?.data["user-details"]?.userForms?.currentStep || "SIGN_UP",
            hrinTrigger: loginRes?.data["user-details"]?.userForms?.hrinTriggerStatus || false,
            accountRejectionReason: accRejectionStatus,
            successPage: loginRes.data["user-details"]?.userForms?.currentStep === "ONBOARDING_COMPLETE" ? true : false
          };
          dispatch(updateUserInfo(usersinfoData));
          dispatch(setUserEntity(loginRes?.data["user-details"]?.entity));
          if (currentStep === "ONBOARDING_COMPLETE") {
            fetch(configurationServices(userEntity).languageListURL)
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                const newLangaugeList = Object.entries(data).map(([key, value]) => ({
                  key,
                  value
                }));
                dispatch(storelanguageList(newLangaugeList));
                let langParam = newLangaugeList.filter((item) => item.key === localStorage.getItem("i18nextLng"));
                if (langParam.length > 0 && langParam[0]) {
                  //dispatch(setUserEntity(loginRes?.data["user-details"]?.userEntity))
                  if (loginRes.data["user-details"].countryOfResidence === "DE") {
                    dispatch(languagequeryParam(loginRes.data["user-details"].userLocale));
                  } else {
                    dispatch(languagequeryParam(localStorage.getItem("i18nextLng")));
                  }
                }
                else {
                  dispatch(languagequeryParam(loginRes.data["user-details"].userLocale));
                }
              })
              .catch((e) => {
                console.log(e.message);
              });
          } else {
            dispatch(languagequeryParam(loginRes.data["user-details"].userLocale));
          }

          if (user?.authToken) {
            const config = { ...getHeaders(authToken, user) };
            customAxios.post(BASE_URL, {
              processName: "annualIncome",
              requestBody: {},
              methodType: "POST"
            },
              config
            ).then((response) => {
              let annualIncomeList = [];
              let netWorthList = [];
              for (const [key1, value1] of Object.entries(
                response.data.data["annual-income-response"].annualIncome
              )) {
                annualIncomeList.push({
                  position: key1,
                  value: value1,
                });
              }
              for (const [key1, value1] of Object.entries(
                response.data.data["annual-income-response"].netWorth
              )) {
                netWorthList.push({ position: key1, value: value1, });
              }
              const thresholdList = { annualIncomeList, netWorthList };
              dispatch(thresholdDataSave(thresholdList));

            });
            if (loginRes && currentStep && authToken)
              navigateToSpecificPage(loginRes, currentStep, authToken);
          }
        } else {
          navigate("/link-error");
        }
      } else {
        dispatch(notifyApiFail("SOMETHING_WENT_WRONG"))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLoginResponse, user?.authToken]);
/* istanbul ignore next */
  const navigateToSpecificPage = (loginRes, currentStep, authToken) => {//NOSOANAR
    const loginResponsePKA = loginRes?.data["user-details"]?.pkainfo ? loginRes.data["user-details"].pkainfo : false;
    const pkaAllInfo = JSON.parse(loginResponsePKA);
    const applicationStatus = loginRes?.data["user-details"]?.userForms?.applicationStatus ? loginRes?.data["user-details"]?.userForms?.applicationStatus : "";
    if (
      loginRes?.data["user-details"]?.entity !== 'FXCM_AU' && applicationStatus === "Complete"
    ) {
      navigate("/tax");
    } else if (
      currentStep === "FORM_OF_ID" ||
      currentStep === "TRADING_INFO" ||
      currentStep === "EMPLOYMENT_INFO" ||
      currentStep === "FINANCIAL_INFO" ||
      currentStep === "CONTACT_INFO" ||
      currentStep === "PERSONAL_INFO" ||
      currentStep === "DENOMINATION" ||
      (userEntity === "FXCM_AU" && currentStep === "PKA_QUIZ")
    ) {
      navigate("/userInfo");
    } else if (currentStep === "PLATFORM") {
      navigate("/currency-select");
    } else if (currentStep === "HIGH_RISK_INVESTMENT") {
      navigate("/termsofbusiness");
    } else if (currentStep === "QUALIFICATION_QUESTIONS") {
      navigate("/termsofbusiness");
    } else if (currentStep === "ONBOARDING_COMPLETE") {
      if (loginRes?.data["user-details"]?.userForms?.accountRejectionReason) {
        navigate("/rejection");
      } else {
        if (
          loginRes?.data?.["user-details"]?.fxowsAccountNumber &&
          loginRes?.data?.["user-details"]?.[
            "userForms"
          ]?.fundingStatus?.toLowerCase() === "allowed"
        ) {
          getPaymentStatusByFxowaccount(
            userInfoData,
            loginRes?.data?.["user-details"]?.fxowsAccountNumber,
            navigate,
            authToken
          );
        } else {
          navigate("/docUpload");
        }
      }
    } else if (
      loginRes?.data["user-details"] &&
      loginRes?.data["user-details"]?.userForms?.hrinTriggerStatus ===
      true
    ) {
      navigate("/riskdisclosure");
    } else if (
      (userEntity === "FXCM_SVG" &&
        currentStep === "FRIENDS_FAMILY_REFERAL" &&
        loginRes.data["user-details"]?.userForms?.hrinStatus === false) ||
      (userEntity === "FXCM_UK" &&
        currentStep === "TRADING_INFO" &&
        loginRes.data["user-details"]?.userForms?.hrinStatus === false) ||
      (userEntity === "FXCM_EU" &&
        currentStep === "PKA_QUIZ" &&
        pkaAllInfo?.answer5 &&
        loginRes.data["user-details"]?.userForms?.hrinStatus === false)
    ) {
      navigate("/riskdisclosure");
    } else if (
      (userEntity === "FXCM_SVG" &&
        currentStep === "FRIENDS_FAMILY_REFERAL" &&
        loginRes.data["user-details"]?.userForms?.hrinTriggerStatus ===
        undefined) ||
      (userEntity === "FXCM_UK" &&
        currentStep === "TRADING_INFO" &&
        loginRes.data["user-details"]?.userForms?.hrinTriggerStatus ===
        undefined) ||
      (userEntity === "FXCM_EU" &&
        currentStep === "PKA_QUIZ" &&
        pkaAllInfo?.answer5 &&
        loginRes.data["user-details"]?.userForms?.hrinTriggerStatus ===
        undefined)
    ) {
      navigate("/termsofbusiness");
    } else if (
      currentStep === "MANUAL_REVIEW" ||
      currentStep === "DEPOSIT"
    ) {
      navigate("/tax");
    } else {
      navigate("/platform");
    }
  }

/* istanbul ignore next */
  useEffect(() => {
    if ((resetPasswordParam || (saveAndResumeParam && ip)) && isLinkValid) {
      setIsLinkValid(false);
      const resetPasswordParamToken = queryParameters.get("token");
      if (resetPasswordParam) {
        /* Launch the reset password flow */
        localStorage.removeItem("uPassToken");
        dispatch(linkExpiredValidationService(resetPasswordParamToken));
      } else {
        /* Launch Save and Resume Flow */
        if (!Object.hasOwnProperty(autoLoginResponse))
          dispatch(saveandResumeTokenValidation(resetPasswordParamToken, ip));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
/* istanbul ignore next */
  useEffect(() => {
    if (referingBroker) {
      dispatch(setReferingBroker(referingBroker));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referingBroker]);

  return (
    <Box data-testid="mainBox" >
      {(resetPasswordParam || saveAndResumeParam) ? <Loader /> : <SignUpForm />}
    </Box>
  );
};
export default OnboardingPage;
