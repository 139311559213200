import React, { useEffect, useState } from "react";
import { liveLogo } from "../../../constants/ImageConstants";
import { Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useDirectionHook from "../../../hooks/useDirectionHook";
import FXCMTrans from "../../../components/common/FXCMTrans";
import { PropTypes } from "prop-types";
const OpenChat = ({open=false}) => {
	const [liveChat] = useState({ width: window.innerWidth });

	const { liveChatStatus, languageParam, liveAgentID } =
		useSelector((state) => state.signUp);
	const [direction] = useDirectionHook(languageParam) //custom hook
	const { t: getLabel } = useTranslation();
	const [chatID, setChatID] = useState();
	const [isOnline, setIsOnline] = useState(false);
	const { width } = liveChat;
	const isMobile = width <= 599;

	useEffect(() => {
		setIsOnline(liveChatStatus?.newStatus);
		setChatID(liveChatStatus?.chatID);
	}, [liveChatStatus?.newStatus, liveChatStatus?.chatID]);

	const initializeChat = () => {
		if (isOnline && chatID) {
			window.liveagent.startChat(chatID);
		}
	};

	return (
		<>
			{liveAgentID && !open ? (
				<Button
					variant="text"
					className={isMobile ? "button-Mobile button-liveChat" : `button-text button-liveChat ${direction ? "button-textArabic" : ""}`} // NOSONAR
					onClick={initializeChat}
					id="liveagent_button_online_ID"
					sx={{ mt: !isMobile && 1.5 }}
					disabled={!isOnline}
				>
					{!isMobile && getLabel("LIVE_CHAT")}
					<img
						src={liveLogo}
						alt="livelogo"
						className={isMobile ? "button-text-Mobile" : "liveLogo"}
					/>
				</Button>
			) : (
				""
			)}

		 {open && 
			<Box className="liveChatMessage"> 
				<FXCMTrans onClick={initializeChat} defaults={getLabel("AUTHORIZATION_FAILED")}	/> 
			</Box> }    					
		</>
	);
};
OpenChat.propTypes = {
	open: PropTypes.bool
  };
export default OpenChat;