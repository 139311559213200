import React, { useState, useEffect } from "react";
import {
	FormControl,
	Box,
	Typography,
	InputLabel,
	OutlinedInput,
	IconButton,
	InputAdornment,
	FormHelperText,
} from "@mui/material";
import {
	languagequeryParam
} from '../../reducers/signUpSlice'
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FXCMTrans from "../../components/common/FXCMTrans";
import Header from "../../components/layout/Header";
import { configurationServices } from "../../constants/configurationServices";
import { passwordIcon, unsuccessfulWarning } from "../../constants/ImageConstants";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordService } from "../../services/auth.service";
import FXCMErrorHandling from "../../components/common/FXCMErrorHandling";
import EsmaBanner from "../../components/layout/EsmaBanner/EsmaBanner";
import useDirectionHook from "../../hooks/useDirectionHook";
import { analytics } from "../../utils/analytics/analytics";

const ResetPassword = () => {//NOSONAR
  const { t: getLabel } = useTranslation();
  const [config, setConfig] = useState();
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [focus, setFocus] = useState(false);
  const { resetPasswordResponse, userEntity,languageParam } = useSelector((state) => state.signUp);
  const [direction] = useDirectionHook(languageParam);
  const [resetPasswordLoader, setResetPasswordLoader] =useState(false)
  const [userLang] =useState(localStorage.getItem("userLocale") ? localStorage.getItem("userLocale") : localStorage.getItem("i18nextLng"))

  const [values, setValues] = useState({
    email: localStorage.getItem("usermail"),
    userId: localStorage.getItem("userId"),
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [error, setError] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [mandatoryError, setmandatoryError] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  useEffect(() => {
    fetchJson();
  }, []);
/* istanbul ignore next */
  useEffect(() => {
    if (error.newPassword === true || error.confirmPassword === true || values.confirmPassword === "" || values.newPassword === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [error, values]);
/* istanbul ignore next */
	const fetchJson = () => {
		fetch(configurationServices().LogInURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setConfig(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
/* istanbul ignore next */
	useEffect(() => {
    dispatch(languagequeryParam(userLang))
		if (
			resetPasswordResponse && //NOSONAR
			resetPasswordResponse.success &&
			resetPasswordResponse.data
    ) {
      localStorage.removeItem("uPassToken");
      setResetPasswordLoader(false);
			navigate("/resetPasswordConfirmation");
		} else {
      setResetPasswordLoader(false);
			setResetPasswordError(resetPasswordResponse.error);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetPasswordResponse]);
/* istanbul ignore next */
  const handleClickShowPassword = (props) => (event) => {
    setValues({
      ...values,
      [props]: !values[props],
    });
  };/* istanbul ignore next */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let validPasswordOne;
  let validPasswordTwo;
  let validPasswordThree;
  let validPasswordFour;

  let specialCAndSpace = true;
/* istanbul ignore next */
  if (specialCAndSpace) {
    validPasswordFour = "green";
    if (/\s/.test(values.newPassword)) {
      validPasswordFour = "red";

      specialCAndSpace = false;
    }
    if (/[<>\\±§/+-]/.test(values.newPassword)) {
      validPasswordFour = "red";

      specialCAndSpace = false;
    }
  }
  let passLength = /^.{8,15}$/.test(values.newPassword);
  if (passLength) {/* istanbul ignore next */
    validPasswordOne = "green";
  } else if (focus) {/* istanbul ignore next */
    validPasswordOne = "red";
  }/* istanbul ignore next */
  if (values.newPassword.length > 15) {
    validPasswordOne = "red";
    passLength = false;
  }

  const lowerVal = /[0-9]/.test(values.newPassword);
  const letterUpperVal = /[A-Z]/.test(values.newPassword);
  const letterVal = /[a-z]/.test(values.newPassword);
/* istanbul ignore next */
  if (letterVal && letterUpperVal) {
    validPasswordTwo = "green";
  } else if (focus) {/* istanbul ignore next */
    validPasswordTwo = "red";
  }
  /* istanbul ignore next */
  if (lowerVal) {/* istanbul ignore next */
    validPasswordThree = "green";
  } else if (focus) {/* istanbul ignore next */
    validPasswordThree = "red";
  }/* istanbul ignore next */
  const handleChange = (prop) => (event) => {
    if (prop === "confirmPassword" && values.newPassword !== "") {
      if (values.newPassword !== event.target.value) {
        setError({ ...error, confirmPassword: true });
        setmandatoryError({ ...error, confirmPassword: false });
      } else {
        setError({ ...error, confirmPassword: false });
      }
    } else if (prop === "newPassword" && values.confirmPassword !== "") {
			if (values.confirmPassword !== event.target.value) {
				setError({ ...error, confirmPassword: true });
				setmandatoryError({ ...error, confirmPassword: false });
			} else {
				setError({ ...error, confirmPassword: false });
			}
		}
    setValues({ ...values, [prop]: event.target.value });
  };/* istanbul ignore next */
  const _onUpdatePassword = () => {
    let newPasswordError = false;
    let confirmPasswordError = false;
    localStorage.removeItem("userLocale");
    if (values.newPassword === "") {
      newPasswordError = true;
    }
    if (values.confirmPassword === "") {
      confirmPasswordError = true;
    }
    setError({
      ...error,
      newPassword: newPasswordError,
      confirmPassword: confirmPasswordError,
    });
    setmandatoryError({
      ...error,
      newPassword: newPasswordError,
      confirmPassword: confirmPasswordError,
    });
    if (values.newPassword === values.confirmPassword) {
      setResetPasswordLoader(true);
      dispatch(resetPasswordService(values.newPassword, values.email));
    }
    analytics?.trackEvent("targeted_click", {User_Id: values.userId, engagementContext: "password_reset" });
  };/* istanbul ignore next */
  const handleOnKeyPress = () => {
    if (specialCAndSpace && lowerVal && letterVal && passLength) {
      setError({ ...error, newPassword: false });
    }
  };/* istanbul ignore next */
  const handleBlurPassword = () => {
    setFocus(true);
    if (specialCAndSpace && lowerVal && letterVal && passLength) {
      setError({ ...error, newPassword: false });
    } else {
      setError({ ...error, newPassword: true });
    }
  };/* istanbul ignore next */
  const handleOnfocus = () => {
    setFocus(false);
  };

  const uPassToken = localStorage.getItem("uPassToken")
  /* istanbul ignore next */
  if (config) {/* istanbul ignore next */
    return (
      uPassToken ?
        <Box>
        <div className={userEntity === "FXCM_EU" ? "customer-header2" : ''}>
        {userEntity === "FXCM_EU" ? <EsmaBanner key="esmaBannerId" /> : ''}
        <Header />
        <Box mt="144px">
          {resetPasswordResponse.data === false ? (
            <FXCMErrorHandling
              image={unsuccessfulWarning}
              titleCondition={true}
              title={config.RESET_PASSWORD_UNSUCESSFUL_BAR_TITLE}
              subTitleCondition={true}
              subTitle={config.RESET_PASSWORD_UNSUCESSFUL_BAR_SUBTITLE}
            />
          ) : (
            ""
          )}

					<div className="container-home update_password">
						<Box textAlign="center">
							<img
								className="image-login"
								src={passwordIcon}
								alt="passwordIcon"
							/>
							<Typography className="heading-1" data-testid="heading">
								{getLabel(config.RESET_PASSWORD)}
							</Typography>
							{resetPasswordError && (
								<Typography className="error_text" sx={{ mt: "24px" }}>
									{resetPasswordError}
								</Typography>
							)}
							<Typography
								className="subtext-cards"
								sx={{ mt: "15px", mb: "8px" }}
							>
								<FXCMTrans defaults={getLabel(config.REST_PASSWORD_SUBTEXT)} />
							</Typography>
						</Box>

            <form>
              <FormControl
                fullWidth
                sx={{ mt: 2 }}
                variant="outlined"
                className={
                  error.newPassword && direction.status
                    ? "error-password custom-form-controler custom_form_controler_arabic"
                    : error.newPassword //NOSONAR
                    ? "error-password custom-form-controler"
                    : direction.status//NOSONAR
                    ? "custom-form-controler custom_form_controler_arabic"
                    : "custom-form-controler"
                }
              >
                <InputLabel>{getLabel(config.RESET_PASSWORD_LABEL)}</InputLabel>
                <OutlinedInput
                  type={values.showNewPassword ? "text" : "password"}
                  value={values.newPassword}
                  onChange={handleChange("newPassword")}
                  onBlur={handleBlurPassword}
                  onFocus={handleOnfocus}
                  onKeyDown={handleOnKeyPress}
                  error={error.newPassword ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword("showNewPassword")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showNewPassword ? (
                          <span className="material-symbols-outlined">
                            remove_red_eye
                          </span>
                        ) : (
                          <span className="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}

                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
                {error.newPassword && mandatoryError.newPassword ? (
                  <FormHelperText>
                    {getLabel(config.MANDATORY_CHECK)}
                  </FormHelperText>
                ) : error.newPassword || (values.newPassword && !values.confirmPassword)? (//NOSONAR
                  <Box
                    className="password-validation-wrapper"
                    // sx={{ p: 1.5, mt: 2 }}
                    sx={error.newPassword ? {background :"#FDF0F2", p: 1.5, mt: 2} : {background :"#fafafa", p: 1.5, mt: 2 }}
                  >
                    <Typography  className="default">
                      {getLabel(config.PASSWORD_MUST)}
                    </Typography>
                    <ul>
                      <li
                        className={
                          validPasswordOne === "green"
                            ? "greenTick"
                            : validPasswordOne === "red"//NOSONAR
                            ? "redCross"
                            : "default"
                        }
                      >
                        {getLabel(config.PASSWORD_LENGTH)}
                      </li>
                      <li
                        className={
                          validPasswordTwo === "green"
                            ? "greenTick"
                            : validPasswordTwo === "red"//NOSONAR
                            ? "redCross"
                            : "default"
                        }
                      >
                        {getLabel(config.PASSWORD_CONTAIN)}
                      </li>
                      <li
                        className={
                          validPasswordThree === "green"
                            ? "greenTick"
                            : validPasswordThree === "red"//NOSONAR
                            ? "redCross"
                            : "default"
                        }
                      >
                        {getLabel(config.PASSWORD_MIXIN)}
                      </li>

                      {!specialCAndSpace && (
                        <li
                          className={
                            validPasswordFour === "green"
                              ? "greenTick"
                              : validPasswordFour === "red"//NOSONAR
                              ? "redCross"
                              : "default"
                          }
                        >
                          {getLabel(config.PASSWORD_SPACES_OR_SPECIAL)}
                        </li>
                      )}
                    </ul>
                  </Box>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl
                fullWidth
                sx={{ mt: 2 }}
                variant="outlined"
                className={
                  error.confirmPassword && direction.status
                    ? "error-password custom-form-controler custom_form_controler_arabic"
                    : error.confirmPassword//NOSONAR
                    ? "error-password custom-form-controler"
                    : direction.status//NOSONAR
                    ? "custom-form-controler custom_form_controler_arabic"
                    : "custom-form-controler"
                }
              >
                <InputLabel>{getLabel(config.CONFIRM_PASSWORD)}</InputLabel>
                <OutlinedInput
                  type={values.showConfirmPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  onChange={handleChange("confirmPassword")}
                  error={error.confirmPassword ? true : false}//NOSONAR
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword("showConfirmPassword")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showConfirmPassword ? (
                          <span className="material-symbols-outlined">
                            remove_red_eye
                          </span>
                        ) : (
                          <span className="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
                {error.confirmPassword && mandatoryError.confirmPassword ? (
                  <FormHelperText>
                    {getLabel(config.MANDATORY_CHECK)}
                  </FormHelperText>
                ) : error.confirmPassword ? (//NOSONAR
                  <FormHelperText>
                    {getLabel(config.PASSWORD_MATCH)}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
							<Box>
								<div className="align_center update_button">
                  <LoadingButton
										loading={resetPasswordLoader}
										loadingPosition="end"
										onClick={_onUpdatePassword}
										className="update_button"
										disabled={disabled}
										sx={{ mt: 3, mb: 10 }}
										variant="contained"
									>
										{getLabel("UPDATE_BUTTON")}
									</LoadingButton>
								</div>
								{/* </Grid> */}
								{/* </Grid> */}
							</Box>
						</form>
					</div>
				</Box>
        </div>
			</Box> : navigate(`/login?lang=${userLang}`)
		);
	}
};

export default ResetPassword;
