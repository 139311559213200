import React, { createContext, useState, useMemo } from 'react';

// Create Context
export const WelcomeBackContext = createContext();

// Create Provider Component
export const WelcomeBackProvider = ({ children }) => {
    const [ welcomeScreen, setWelcomeScreen ] = useState( false );
    const [ isAlreadyWelcomed, setIsAlreadyWelcomed ] = useState( false );

    const showWelcomeBack = () => {
        setWelcomeScreen(true);
    };

    const hideWelcomeBack = () => {
        setWelcomeScreen( false );
        setIsAlreadyWelcomed( true );
    };

    // Memoize the context value
    const contextValue = useMemo(() => ({
        welcomeScreen,
        showWelcomeBack,
        hideWelcomeBack,
        isAlreadyWelcomed
    }), [welcomeScreen, isAlreadyWelcomed]);

    return (
        <WelcomeBackContext.Provider value={contextValue}>
            {children}
        </WelcomeBackContext.Provider>
    );
};
