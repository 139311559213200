/* istanbul ignore file */
import { store } from "../store";
import { configurationServices } from "../constants/configurationServices";
const state = store.getState();

export const isUserLoggedIn = () => {
	let isLoggedIn = false;
	if (state && state.signUp && state.signUp.authRes) {//NOSONAR
		isLoggedIn = true;
	}
	return isLoggedIn;
};

export const getCurrentUrlPath = () => {
	return window.location.pathname;
};

export const getFormattedPKAQuestions = (apiResponse) => {
	let formattedData = [];

	const pkainfo =
		apiResponse && //NOSONAR
		apiResponse["pka-response"] &&
		apiResponse["pka-response"].pkainfo
			? apiResponse["pka-response"].pkainfo
			: null;
	let questionsAndOptionsList =
		pkainfo && pkainfo.questionsAndOptionsList //NOSONAR
			? pkainfo.questionsAndOptionsList
			: [];
	let allDynamicLabels = {};
	questionsAndOptionsList.forEach((pkaQuestion) => {
		let questioData = {
			header: Object.keys(pkaQuestion.questions)[0],
			dynamicQuestionLabel: Object.values(pkaQuestion.questions)[0],
			options: [],
		};

		Object.keys(pkaQuestion.options).forEach((option) => {
			let optionObj = {
				label: pkaQuestion.options[option],
				value: option,
			};
			questioData.options.push(optionObj);
		});
		allDynamicLabels = {
			...allDynamicLabels,
			...pkaQuestion.questions,
			...pkaQuestion.options,
		};
		formattedData.push(questioData);
	});

	return { formattedData, allDynamicLabels };
};

export const getSortedLocaleAsEnglish = () => {
	fetchJson();
};

const fetchJson = async () => {
	try {
		const response = await fetch(configurationServices().en_labelsList);
		const data = await response.json();

		const localLanguageJsonResponse = await fetch(
			configurationServices().BG_labelsList
		);
		const localLanguageJson = await localLanguageJsonResponse.json();

		let localeObject = {};
		Object.keys(data).forEach((key) => {
			if (key in localLanguageJson) {
				localeObject[key] = localLanguageJson[key];
			}
		});
	} catch (error) {
		console.log("Error in reading english file");
	}
};

export const sumArray = (numbers) => {
	if (!Array.isArray(numbers)) {
	  	throw new Error("Input must be an array");
	}
  
	return numbers.reduce((sum, value) => {
		const num = parseFloat(value);
		if (isNaN(num)) {
			throw new Error("Array must contain only valid numbers or numeric strings");
		}
		return sum + num;
	}, 0);
}

export const truncateTextByWidth = (text, maxWidth, font = "16px Noto Sans", letterSpacing = 0) => {
	if(!text || !maxWidth) return null;
    const canvas = document.createElement("canvas");
    const context = canvas?.getContext("2d");
    if (!context) {
		console.error("Failed to get 2D context");
	} else {
		context.font = font;
	}

    let truncatedText = text;
    let textWidth = context?.measureText(truncatedText).width + (truncatedText.length * letterSpacing);

    while (textWidth > maxWidth && truncatedText.length > 0) {
        truncatedText = truncatedText.slice(0, -1);
        textWidth = context?.measureText(truncatedText + "...").width + ((truncatedText.length + 3) * letterSpacing);
    }
	const finalText = (text === truncatedText) ? truncatedText : (truncatedText + "...");
    return finalText;
};