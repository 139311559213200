/* eslint-disable no-unused-vars */
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import FXCMTrans from "../../components/common/FXCMTrans";
import Header from "../../components/layout/Header";
import { configurationServices } from "../../constants/configurationServices";
import { password } from "../../constants/ImageConstants";
import { useDispatch, useSelector } from "react-redux";
import { analytics } from "../../utils/analytics/analytics";
import useDirectionHook from "../../hooks/useDirectionHook";
import { notifyApiFail } from "../../reducers/errorNotifySlice";
import { getAPIPath } from "../../utils/endPointMapper";
import { customAxios } from "../../services/serviceInterceptor";
import { systemAlertFunc } from "../../services/auth.service";
import { getRequestHeaders } from "../../utils/getUsersDetails";
const appURL = getAPIPath().REACT_APP_URL;

/* istanbul ignore next */
const ForgotPassword = () => {//NOSONAR
	const { t: getLabel } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [fxcmError, setFxcmError] = useState(false);
	const [error, setError] = useState({
		email: false,
		username: false,
	});
	const [forgotPass, setforgotPass] = useState(false);
	const [link, setLink] = useState();
	const[buttonLoader, setButtonLoader] = useState(false);


	const [values, setValues] = useState({
		email: "",
		username: "",
		isChecked: false,
	});

	const [mandatoryError, setmandatoryError] = useState({
		email: false,
		username: false,
	});
	const [forgotPasswordError, setForgotPasswordError] = useState();
	const { languageParam, getAllEnLabels} = useSelector((state) => state.signUp);
	const [config, setConfig] = useState();
	const [isEmailExist,setIsEmailExist] = useState(false);
	const [direction] = useDirectionHook(languageParam) //custom hook

	const fetchJson = () => {
		fetch(configurationServices().LogInURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setConfig(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	useEffect(() => {
		fetchJson();
	}, []);

	const defaultLocale =localStorage.getItem("i18nextLng");
	const fetchSupportLinkJson = () => {
		fetch(configurationServices().supportLinks)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
			const localeToUse = languageParam || defaultLocale;
				 const filteredDenominationArray = data?.SUPPORT_LINK?.filter((value) => value.locale === localeToUse);
					setLink(filteredDenominationArray[0]?.link)
			})
			.catch((e) => {
				console.log(e.message);
			});
	};


  useEffect(() => {
		fetchSupportLinkJson();
	}, [languageParam]);

	useEffect(() => {
		if (forgotPass) {
				analytics?.trackEvent("forgot_password", {});
				navigate("/forgotpasswordConfirm", { state: { email: values.email,success:isEmailExist } });
		}
	}, [forgotPass, dispatch, navigate, values]);

	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			values.email
		);
		

	const handleChange = (prop) => (event) => {
		if (prop === "username") {
			setmandatoryError({ ...error, username: false });
		}

		if(prop === "username") {
            if(event.target.value === "" ) {
				setmandatoryError({ ...error, username: true });
				setError({ ...error, username: true });
			} else if(event.target.value.length > 0) {
				setmandatoryError({ ...error, username: false });
				setError({ ...error, username: false });
			}
		}

		setFxcmError(false);
		setValues({ ...values, [prop]: event.target.value });
		if (
			event.target.value.includes("fuhui") ||
			event.target.value.includes("fxcm")
		) {
			setError({ ...error, email: true });
			setFxcmError(true);
		} else {
			setFxcmError(false);
		}
	};

	const handleBlurEmail = () => {
		setmandatoryError({
			...error,
			email: false,
			username: false,
		});
		if (emailRegex) {
			setError({ ...error, email: false });
		} else {
			setError({ ...error, email: true });
		}
	};


	const handleBlurUsername = () => {

		setmandatoryError({
			...error,
			email: false,
			username: false,
		});
		
		if(values.username === "") {
			setmandatoryError({ ...error, username: true });
			setError({ ...error, username: true });
		}
	}


	const handleLabelChange = (e) => {
        const updatedChecked = !values.isChecked;
        const updatedUsername = updatedChecked ? values.email : "";
   
        setValues({
            ...values,
            isChecked: updatedChecked,
            username: updatedUsername,
        });
   
        if (updatedChecked && values.email) {
            setError({ ...error, username: false });
            setmandatoryError({ ...mandatoryError, username: false });
        }
    };

	const handleCancelClick = () => {
		analytics?.trackEvent("targeted_click", { engagementContext: getAllEnLabels["CANCEL"] });
		navigate("/login");
	};

	const validation = () => {
		let isValid = true;
		if (error.username || error.email) {
			isValid = false;
		}
		if (values.email === "" || values.username === "") {
			isValid = false;
		}
		return isValid;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let emailError = false;
		let usernameError = false;

		if (values.email === "") {
			emailError = true;
		} 
		if (values.username === "") {
			usernameError = true;
		}
		
		setError({
			...error,
			email: emailError,
			username: usernameError,
		});

		setmandatoryError({
			...error,
			email: emailError,
			username: usernameError,
		});

		if (validation()) {
			setButtonLoader(true);
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			setValues({ ...values });

			const requestParams = {
				email: values.email,
				username: values.username,
				domainName: window.location.origin
			};
			const requestObj = {
				processName: "resetPassword",
				requestBody: requestParams,
				methodType: "POST",
			};
			const config = {
				...getRequestHeaders()
			};
			customAxios
				.post(appURL + "/resetpassword", requestObj, config)
				.then((response) => {
					if(response.data.httpStatus === "SERVICE_UNAVAILABLE"){
						dispatch(notifyApiFail({title: getLabel("SERVICE_UNAVAILABLE"),
						desc: getLabel("SERVICE_UNAVAILABLE"),
						type: "Failed",},
						));
						dispatch(systemAlertFunc(config?.headers?.requestid, response?.data?.type, response?.data?.message));
						
					}
					else{
						setIsEmailExist(response.data.success);
						setforgotPass(true);
					}
					setButtonLoader(false);
				})
				.catch((err) => {

					dispatch(notifyApiFail({title: getLabel("SERVICE_UNAVAILABLE"),
					desc: getLabel("SERVICE_UNAVAILABLE"),
					type: "Failed",},
					));
					dispatch(systemAlertFunc(config?.headers?.requestid, err?.type, err?.message));
					setButtonLoader(false);
					
				});
		}
	};

	let enabled = !(emailRegex && values.email !== "" && values.username !== "");

	if (config) {
		return (
			<Box mt="144px">
				<Header screenType="forgotpassword" />
				    <Box sx={{ mt: "97px" }}>
						<div className="container-home">
							<Box textAlign="center">
								<img className="image-login" src={password} alt="logInIcon" />
							<Typography className="heading-1" sx={{ mt: "20px" }} data-testid="forgot_pass_header">
									{getLabel(config.FORGET_PASSWORD)}
								</Typography>
							<Typography className="subtext-cards" sx={{ mt: "24px" }} data-testid="forgot_pass_header_label">
									<FXCMTrans
										defaults={getLabel(config.FORGOT_PASSWORD_LABEL)}
									/>
								</Typography>
							</Box>
							<form>
								<FormControl
									fullWidth
									sx={{ mt: "20px" }}
									className={
										forgotPasswordError && direction.status
											? "custom-form-controler-error custom_form_controler_arabic"
											: direction.status
											? "custom-form-controler custom_form_controler_arabic"
											: "custom-form-controler"
									}
								>
									<TextField
										value={values.email}
										onChange={handleChange("email")}
										inputProps={{ maxLength: 50 }}
										onBlur={handleBlurEmail}
										name="email"
										error={error.email ? true : false}
										helperText={
											error.email
												? fxcmError
													? getLabel(config.EmailFXCMERROR)
													: mandatoryError.email
													? getLabel(config.MANDATORY_CHECK)
													: getLabel(config.EmailErrorText)
												: forgotPasswordError
												? getLabel(config.HELPER_TEXT_EMAIL_NF)
												: ""
										}
										label={getLabel(config.EMAIL)}
									/>
								</FormControl>

								<FormControl className="forgotChekbox">
									<FormControlLabel 
										control={
											<Checkbox checked={values.isChecked} size="small" />
										}
										onChange={handleLabelChange}
										label={getLabel("EMAIL_ADDRESS_LABEL")}
									/>
								</FormControl>

								<FormControl
									fullWidth
									sx={{ mt: "20px" }}
									className={
										direction.status
											? "custom-form-controler custom_form_controler_arabic"
											: "custom-form-controler"
									}
								>
									<TextField
										value={values.username}
										onChange={handleChange("username")}
										inputProps={{ maxLength: 50 }}
										onBlur={handleBlurUsername}
										error={error.username ? true : false}
										helperText = {error.username  && mandatoryError ? getLabel(config.MANDATORY_CHECK) : ""}
										// helperText={
										// 	error.username
										// 		? fxcmError
										// 			? getLabel(config.UserErrorText)
										// 			: mandatoryError.username
										// 			? getLabel(config.MANDATORY_CHECK)
										// 			: getLabel(config.INVALID_EMAIL_SUBTITLE)
										// 		: ""
										// }
										label={getLabel(config.USERNAME)}
									/>
								</FormControl>

								<Box sx={{ mt: 3 }}>
									<Grid
										container
										spacing={1}
										className={
											direction.status
												? "forgot-button_Arabic"
												: "forgot-button"
										}
									>
										<Grid item xs={6}>
											<Button
												fullWidth
												variant="outline"
												sx={{ my: 1 }}
												onClick={handleCancelClick}
											>
												{getLabel("CANCEL")}
											</Button>
										</Grid>
										<Grid item xs={6}>
											<LoadingButton
												loading={buttonLoader}
												loadingPosition="end"
												type="submit"
												disabled={enabled}
												fullWidth
												sx={{ mt: 1 }}
												variant="contained"
												onClick={handleSubmit}
											>
												{getLabel("SUBMIT")}
											</LoadingButton>
										</Grid>
									</Grid>
								</Box>
								<Typography
									textAlign="center"
									sx={{ mt: 3 }}
									className="support-text"
								data-testid="forgot_pass_support"
								>
									{getLabel("SUPPORT_TEXT")}
									<span>
									<a href={`${link}`} 
									onClick={(e)=>{
										analytics?.trackEvent("targeted_click",{engagementContext:"fxcm_support"});
									}
									  }
									target="_blank" 
									rel="noopener noreferrer">
										{getLabel("FXCM_SUPPORT")}
									</a>
									</span>
								</Typography>
							</form>
						</div>
					</Box>
			</Box>
		);
	}
};

export default ForgotPassword;
