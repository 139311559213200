/**
 * A reusable component that displays the description for a card.
 */
import { Typography } from "@mui/material";

const CardPara = (props) => {
	return (
		<div>
			{props.value && (
				<Typography sx={{ mt: 3 }} className="para-1">
					{props.value}
				</Typography>
			)}
		</div>
	);
};

export default CardPara;
