import { store } from "../store";
const CDN_URL = process.env.REACT_APP_CDN_URL;
export const agreeSaveDateURL = "https://fakestoreapi.com/products";
 
/* istanbul ignore next */
export const getEsmabarText = (entity) => {
	const selectedEntity = commonSwitch(entity);
	return `https://fxp-api.fxcorporate.com/${selectedEntity}/wp-json/fxpress/v1/profitability`;
};
 
/* istanbul ignore next */
const commonSwitch = (selectedEntity) => {
	let configPath = "";
	switch (selectedEntity) {
		case "FXCM_SVG":
			configPath = "svg";
			break;
		case "FXCMNO":
			configPath = "no";
			break;
		case "FXCM_EU":
			configPath = "eu";
			break;
		case "FXCM_UK":
			configPath = "uk";
			break;
		case "FXCM_AU":
			configPath = "au";
			break;
		default:
			configPath = "eu";
	}
	return configPath;
};

export const configurationServices = (userEntity, lang) => { 
/* istanbul ignore next */
	const state = store.getState(); 
/* istanbul ignore next */
	const selectedEntity = state.signUp.userEntity; 
/* istanbul ignore next */
	let configPath = commonSwitch(userEntity ? userEntity : selectedEntity);

	return {
		signUpURL: `${CDN_URL}/configurations/${configPath}/signUpConfig.json`,
		languageListURL: `${CDN_URL}/configurations/${configPath}/languageList.json`,
		LogInURL: CDN_URL + "/configurations/LogInConfig.json",
		countryList: CDN_URL + "/configurations/countryList.json",
		restrictedCountriesURL:
			CDN_URL + "/configurations/restrictedCountries.json",
		en_labelsList: CDN_URL + "/locales/en-US/language.json",
		labelsList: CDN_URL + `/locales/${lang}/language.json`,
		idsCardList: CDN_URL + "/configurations/idsCard.json",
		platformURL: `${CDN_URL}/configurations/platform.json`,
		transliterationURL: `${CDN_URL}/configurations/transliteration.json`,
		crsURL: CDN_URL + "/configurations/crsConfig.json",
		paymentImages: CDN_URL + "/configurations/paymentsImages.json",
		paymentConfig: CDN_URL + "/configurations/paymentConfig.json",
		UserMessage: `${CDN_URL}/configurations/userMessage.json`,
		commonConfig: `${CDN_URL}/configurations/commonConfig.json`,
		BG_labelsList: CDN_URL + `/locales/bg-BG/language.json`,
		commonLanguageListURL: `${CDN_URL}/configurations/languageList.json`,
        paymentVendorsTitle:`${CDN_URL}/configurations/paymentVendorTitle.json`,
        tinFields: `${CDN_URL}/configurations/crsValidationV2.json`,
		vendorConfig: `${CDN_URL}/configurations/vendorConfig.json`,
		supportLinks: `${CDN_URL}/configurations/fxcmSupportlinks.json`,
		domains: CDN_URL + "/configurations/domain.json",

		sectionConfigURL: `${CDN_URL}/configurations/${configPath}/sectionConfig.json`,
		sectionConfigTwoURL: `${CDN_URL}/configurations/${configPath}/sectionConfigTwo.json`,
		cardConfigURL: `${CDN_URL}/configurations/${configPath}/cards/`,
		CurrencyURL: `${CDN_URL}/configurations/${configPath}/currency.json`,
		hrinConfigURL: `${CDN_URL}/configurations/${configPath}/hrinConfig.json`,
		popUpConfigURL: `${CDN_URL}/configurations/${configPath}/popUpConfig.json`,
		tobConfigURL: `${CDN_URL}/configurations/${configPath}/tobConfig.json`,
		rejectionURL: `${CDN_URL}/configurations/${configPath}/rejection.json`,
		expectationsCardURL: `${CDN_URL}/configurations/${configPath}/expectationsCard.json`,
		contactnumberCardURL: `${CDN_URL}/configurations/${configPath}/contact.json`,
		loqateConfigURL: `${CDN_URL}/configurations/${configPath}/loqate.json`,
		welcomeBackURL: `${CDN_URL}/configurations/${configPath}/welcomeBack.json`,
		crsConfigSec: `${CDN_URL}/configurations/${configPath}/crsConfig.json`,
		paymentVendors: `${CDN_URL}/configurations/${configPath}/payments/paymentVendors.json`,
		analyticsList: CDN_URL + "/configurations/analyticsList.json",
		dateFormatList: CDN_URL + "/configurations/dateFormatList.json",
		quizConfig: `${CDN_URL}/configurations/${configPath}/quizConfig.json`,
		authentixConfig: `${CDN_URL}/configurations/${configPath}/authentixConfig.json`,
		factaRequirementURL: `${CDN_URL}/configurations/${configPath}/factaRequirementURL.json`
	};
};
