/**
 * This component is rendered when the user log out mid way without completing the onboarding and again sign in into the application.
 */
/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Box, Button } from "@mui/material";
import CardHeader from "../CardHeader";
import CardSubHeader from "../CardSubHeader";
import CardPara from "../CardPara";
import { useTranslation } from "react-i18next";
import { iconFolder } from "../../constants/ImageConstants";
import FXCMTrans from "../common/FXCMTrans";
import { configurationServices } from "../../constants/configurationServices";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {PropTypes} from 'prop-types';
import "./style.scss";
import Loader from "../Loader";

const WelcomeBack = ({ welcomeToggle, name }) => {
  const [formData, setFormData] = useState({});
  const { userEntity } = useSelector((state) => state.signUp);
  const fetchJson = () => {
    fetch(configurationServices(userEntity).welcomeBackURL)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFormData(data);
      })
      .catch((e) => {
        /* istanbul ignore next */
        console.log(e.message);
      });
  };

  useEffect(() => {
    fetchJson();
  }, []);

  const { t: getLabel } = useTranslation();

  if (Object.keys(formData).length === 0)
    return <Loader />

  return (
    <Box align="center" my={3} className="welcome" data-testid="welcome_id">
      <Container maxWidth={false} sx={{ px: 1, maxWidth: "500px" }}>
        <img src={iconFolder + formData.headerIcon + ".svg"} alt="Icon" />
        <CardHeader
          title={
            <FXCMTrans
              defaults={getLabel(`${formData.GREETINGS}`)}
              values={{ firstName: name }}
            />
          }
        />
        <h1 data-testid="welcome_header">{getLabel(`${formData.WELCOME_BACK}`)}</h1>
        <section data-testid="problemIcon_id" className="welcomeback-screen">
          <ReportProblemIcon />
          <CardPara value={getLabel(`${formData.INCOMPLETE_MESSAGE}`)} />
        </section>
        <CardSubHeader value={getLabel(`${formData.INCOMPLETE_SUBMESSAGE}`)} />
        <Button
          disabled={false}
          onClick={welcomeToggle}
          sx={{ my: 2, minWidth: "140px" }}
          type="submit"
          variant="contained"
          data-testid="test"
        >
          {getLabel(formData.COMPLETE_NOW)}
        </Button>
      </Container>
    </Box>
  );
};
WelcomeBack.propTypes={
	welcomeToggle: PropTypes.func,
	name: PropTypes.string,
}
export default memo(WelcomeBack);
